<template>
  <div class="page-container">
    <header v-if="promotion.settings.header.show === true" class="header" :style="getStyle('header')">
      <h1 :style="getStyle('header')">
        {{ promotion.name }}
      </h1>
    </header>
    <main class="main-content" :style="getStyle('content')">
      <validation-observer #default="{ handleSubmit }"
                           ref="refFormDocumentObserver"
      >
        <b-form @submit.prevent="handleSubmit(validateDocument)">
          <b-row class="justify-content-center align-content-center align-items-center">
            <b-col sm="3" md="3">
              <i2-form-input
                v-if="isCpf"
                v-model="model.cpf"
                label="Documento (CPF)"
                name="documento"
                rules="required|cpf"
                max-length="11"
                :mask="'###.###.###-##'"
                placeholder="Digite o CPF"
                @input="resetForm"
              />
              <i2-form-input
                v-if="isCnpj"
                v-model="model.cpf"
                label="Documento (CNPJ)"
                name="documento"
                rules="required|cnpj"
                :mask="'##.###.###/####-##'"
                placeholder="Digite o CNPJ"
                @input="resetForm"
              />
              <i2-form-input
                v-if="isCpf === false && isCnpj === false"
                v-model="model.cpf"
                label="Documento (CPF ou CNPJ)"
                name="documento"
                rules="required|cpfCnpj"
                :mask="['###.###.###-##', '##.###.###/####-##']"
                placeholder="Digite o CPF ou CNPJ"
                @input="resetForm"
              />
            </b-col>
            <b-col sm="3" md="3">
              <b-button class="btn-icon" :style="getStyle('button')" @click="validateDocument">
                <feather-icon icon="CheckCircleIcon" style="font-size: 1.7em; padding-right:3px;"/>
                Buscar Cadastro
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <div v-if="isNewParticipant">
        <participant-new-form
          :document="model.cpf"
          :headers="getConfiguration()"
          :button-style="getStyle('button')"
          @onSave="validateDocument"
        />
      </div>
      <i2-divider/>
      <ParticipantFormTextCoupon
        v-if="isShowCodesContainer && !isImageCoupon "
        :promotion-uuid="promotion.uuid"
        :document="model.cpf"
        :http-config="getConfiguration()"
        :get-style="getStyle('button')"
      />

      <ParticipantFormImageCoupon
        v-if="isShowCodesContainer && isImageCoupon "
        :promotion="promotion"
        :document="model.cpf"
        :http-config="getConfiguration()"
        :get-style="getStyle('button')"
      />

    </main>
    <footer v-if="promotion.settings.footer.show === true" class="footer" :style="getStyle('footer')">
      <p>
        <a target="_blank" href="https://globalpromocoes.com/">
          Plataforma Global de Promoções
        </a> - Todos Direitos Reservados {{ currentDate.getFullYear() }} &copy;
      </p>
    </footer>
  </div>
</template>

<script>
import I2Divider from '@/components/i2/content/i2Divider.vue'
import { ValidationObserver } from 'vee-validate'
import ParticipantNewForm from '@/views/public/ParticipantNewForm.vue'
import ParticipantFormTextCoupon from '@/views/public/ParticipantFormTextCoupon.vue'
import ParticipantFormImageCoupon from '@/views/public/ParticipantFormImageCoupon.vue'

export default {
  name: 'Participants',
  components: {
    ParticipantFormImageCoupon,
    ParticipantFormTextCoupon,
    ParticipantNewForm,
    I2Divider,
    ValidationObserver,
  },

  data() {
    return {
      currentDate: new Date(),
      isNewParticipant: false,
      isShowCodesContainer: false,
      isImageCoupon: false,
      personDocument: '',
      promotion: {
        name: 'Carregando...',
        settings: {
          header: {
            background_color: '',
            text_color: '',
            show: true,
          },
          footer: {
            background_color: '',
            text_color: '',
            show: true,
          },
          button: {
            background_color: '',
            text_color: '',
          },
        },
      },
      promotionUuid: '',
      token: '',
      queryData: null,
      model: {
        cpf: '',
        document: '',
        name: '',
        phone: '',
        email: '',
        birthdate: '',
        city: '',
        state: '',
        neighborhood: '',
        address: '',
        complement: '',
        zip_code: '',
        address_number: '',
      },
      coupon: {
        code: '',
      },

    }
  },
  computed: {
    isCpf() {
      return this.promotion.settings.form?.type_document?.value === 'cpf'
    },
    isCnpj() {
      return this.promotion.settings.form?.type_document?.value === 'cnpj'
    },
  },
  beforeCreate() {
    localStorage.clear()
  },
  mounted() {
    const getQueryParam = param => {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get(param)
    }
    this.queryData = JSON.parse(atob(getQueryParam('d')))
    this.coupon.code = getQueryParam('code')
    this.getPromotion()
  },
  methods: {
    resetForm() {
      this.isShowCodesContainer = false
      this.isNewParticipant = false
      this.model = {
        cpf: this.model.cpf,
        document: '',
        name: '',
        phone: '',
        email: '',
        birthdate: '',
        city: '',
        state: '',
        neighborhood: '',
        address: '',
        complement: '',
        zip_code: '',
        address_number: '',
      }
    },
    async getPromotion() {
      this.promotion = await this.$http.get(`/promotion/${this.queryData.uuid}/code`, this.getConfiguration())
      if (this.promotion.settings.footer.show === null) {
        this.promotion.settings.footer.show = true
      }
      if (this.promotion.settings.header.show === null) {
        this.promotion.settings.header.show = true
      }
      this.isImageCoupon = this.promotion.settings.form?.type_coupon?.value === 'photo'
      localStorage.setItem('external_promotion', JSON.stringify(this.promotion))
      if (this.promotion?.settings?.form?.content?.text_color) {
        const textColor = this.promotion.settings.form?.content?.text_color || '#5e5873'
        document.documentElement.style.setProperty('--global-text-color', textColor)
      }
    },
    async validateDocument() {
      this.$refs.refFormDocumentObserver.validate().then(async success => {
        if (!success) {
          return
        }
        const documentSearch = this.model.cpf.replace(/\D/g, '')
        const existParticipant = await this.$http.get(`/person/${documentSearch}`, this.getConfiguration())
        this.isNewParticipant = !existParticipant
        this.isShowCodesContainer = existParticipant
        this.personDocument = this.model.cpf
      })
    },

    getConfiguration() {
      return {
        headers: {
          Authorization: `Bearer ${this.queryData.t}`,
        },
      }
    },
    getStyle(where) {
      if (!this.promotion.settings) {
        return {}
      }
      if (where === 'button' && this.promotion.settings[where]) {
        return {
          backgroundColor: `${this.promotion.settings[where].background_color} !important`,
          borderColor: `${this.promotion.settings[where].background_color} !important`,
          color: `${this.promotion.settings[where].text_color} !important`,
        }
      }

      if (this.promotion.settings[where]) {
        return {
          backgroundColor: this.promotion.settings[where].background_color,
          color: `${this.promotion.settings[where].text_color} !important`,
        }
      }
      return {}
    },
  },
}
</script>

<style>
.page-container label, p, h1, h2, h3, h4, h5, h6{
  color: var(--global-text-color) !important;
}
</style>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 100px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .page-container {
    padding: 10px;
  }
}

.header, .footer {
  width: 100%;
  text-align: center;
}

.header {
  background-color: #F4F8FD;
  padding: 15px 0;
}

.main-content {
  flex: 1;
  background-color: #ffffff;
  padding: 15px;
  width: 100%;
}

.footer {
  background-color: #F4F8FD;
  padding: 15px 0;
}
</style>
