<template>
  <div>
    <b-row>
      <b-col class="text-center">
        <h3>Envie as fotos dos cupons.</h3>
      </b-col>
    </b-row>
    <validation-observer #default="{ handleSubmit }"
                         ref="refFormAddCuponObserver"
    >
      <b-form @submit.prevent="handleSubmit(addCoupon)">
        <b-row class="justify-content-center align-content-center align-items-center">
          <b-col md="4">
            <i2-form-input-money
              v-model="coupon.value"
              label="Valor dos Produtos Participantes"
              name="coupon_value"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="availableDynamicFields.length > 0"
          class="justify-content-center align-content-center align-items-center"
        >
          <b-col v-for="field in availableDynamicFields" :key="field.uuid" md="4">
            <i2-form-input
              :key="field.uuid"
              v-model="modelDynamicField[field.slug]"
              :label="field.label"
              :name="field.slug"
              rules="required"
            />
          </b-col>

        </b-row>
        <b-row class="justify-content-center align-content-center align-items-center">
          <b-col md="5" sm="5">
            <i2-form-input-file
              v-model="coupon.files"
              label="Selecione uma ou mais imagens"
              :multiple="true"
              name="coupon_file"
              rules="required"
            />
          </b-col>
          <b-col md="3" sm="3">
            <b-button class="btn-icon" :style="getStyle" @click="addCoupon">
              <feather-icon icon="PlusIcon" style="font-size: 1.7em; padding-right:3px;"/>
              Adicionar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-row v-if="isShowNumbers" class="justify-content-center align-content-center align-items-center">
        <div class="alert custom-alert-success">
          <h4>Número(s) Gerado(s)</h4>
          <div v-for="item in luckNumbers" :key="item.uuid">
            {{ item.full_number }}
          </div>
        </div>
      </b-row>
      <b-row class="justify-content-center align-content-center align-items-center">
        <div class="alert">
          <h1>Atenção</h1>
          <p>
            Você deve informar a <b>soma dos valores</b> dos produtos
            participantes na promoção <b>{{ currentPromotion.name }}</b> contidos
            na nota fiscal das fotos que serão enviadas.
          </p>
        </div>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>

import { ValidationObserver } from 'vee-validate'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'

export default {
  name: 'ParticipantFormImageCoupon',
  components: { I2FormInputFile, ValidationObserver },
  props: {
    getStyle: {},
    promotion: {
      type: Object,
      required: true,
    },
    httpConfig: {
      type: Object,
      required: true,
    },
    document: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowNumbers: false,
      luckNumbers: [],
      currentPromotion: {},
      coupon: {
        files: '',
        value: '',
      },
      availableDynamicFields: [],
      modelDynamicField: {

      },
    }
  },
  mounted() {
    this.currentPromotion = this.promotion
    this.startFields()
  },
  methods: {
    startFields() {
      this.availableDynamicFields = []
      if (this.currentPromotion.settings.form?.fields?.length > 0) {
        this.currentPromotion.settings.form.fields.forEach(field => {
          if (field.slug !== '') {
            this.modelDynamicField[field.slug] = ''
            this.availableDynamicFields.push(field)
          }
        })
      }
    },
    async addCoupon() {
      this.$refs.refFormAddCuponObserver.validate().then(async success => {
        if (!success) {
          return
        }
        const formData = new FormData()
        this.coupon.files.forEach(file => {
          formData.append('photos[]', file)
        })
        formData.append(
          'promotion_uuid',
          this.currentPromotion.uuid,
        )
        formData.append('cpf', this.document.replace(/\D/g, ''))
        formData.append('total_value', this.coupon.value)
        formData.append('dynamic_fields', JSON.stringify(this.modelDynamicField))

        const response = await this.$http.post(`/promotion/${this.promotion.uuid}/files`, formData, this.httpConfig)
        if (response.error || response.error_message) {
          this.$notify.error(response.error_message)
          return
        }
        this.$notify.success('Cupons enviados e números gerados com sucesso.')
        this.luckNumbers = response
        this.isShowNumbers = true
        this.resetForm()
        this.$refs.refFormAddCuponObserver.reset()
      })
    },
    resetForm() {
      this.startFields()
      this.coupon.files = null
      this.coupon.value = 0
    },
  },
}
</script>
<style scoped>

.alert {
  background-color: #fff9c4; /* Tonalidade suave de amarelo */
  color: #5f5f5f; /* Cinza escuro para texto */
  border: 1px solid #ffeb3b; /* Borda amarela clara */
  border-radius: 8px; /* Cantos arredondados */
  padding: 8px 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra discreta */
  max-width: 400px;
  width: 100%;
}

.alert h1 {
  text-align: left;
  font-size: 10px;
  margin: 0;
  margin-bottom: 10px;
}

.alert p {
  text-align: left;
  margin: 0;
  font-size: 9px;
  line-height: 1.5;
}

.custom-alert-success {
  background-color: #d1fad1; /* Verde suave que remete ao sucesso */
  color: #155724; /* Cor do texto remanescente de sucesso */
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #a2d9a2; /* Uma borda verde que define melhor o conteúdo */
}

</style>
