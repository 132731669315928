<template>
  <validation-observer #default="{ handleSubmit }"
                       ref="refFormNewPersonObserver"
  >
    <b-form @submit.prevent="handleSubmit(saveParticipantData)">
      <b-row>
        <b-col>
          <i2-form-input
            v-model="model.name"
            label="Nome Completo"
            name="name"
            rules="required"
            placeholder="Digite seu nome completo"
          />
        </b-col>
        <b-col>
          <i2-form-input
            v-model="model.email"
            label="E-mail"
            name="email"
            type="email"
            rules="required"
            placeholder="seuemail@exemplo.com"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <i2-form-input
            v-model="model.phone"
            label="Telefone"
            mask="(##) # ####-####"
            name="phone"
            rules="required"
            placeholder="(51) 9 9999-9999"
          />
        </b-col>
        <b-col>
          <i2-form-vue-select
            v-model="model.sex"
            label="Sexo"
            name="sex"
            rules="required"
            :options="availableSexCollection"
          />
        </b-col>
        <b-col>
          <i2-form-input
            v-model="model.birthdate"
            label="Data de Nascimento"
            mask="##/##/####"
            name="birthdate"
            rules="required"
            placeholder="01/01/2000"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          sm="3"
        >
          <i2-form-input
            v-model="model.zip_code"
            label="CEP"
            name="cep"
            rules="required"
            mask="#####-###"
            placeholder="99999-999"
            @change="searchZipCode"
          />
        </b-col>
        <b-col
          md="4"
          sm="4"
        >
          <i2-form-input
            v-model="model.city"
            label="Cidade"
            name="city"
            rules="required"
            placeholder="Nome da Cidade"
          />
        </b-col>
        <b-col
          md="2"
          sm="2"
        >
          <i2-form-input
            v-model="model.state"
            label="UF"
            name="state"
            rules="required"
            placeholder="RS"
          />
        </b-col>
        <b-col
          md="3"
          sm="3"
        >
          <i2-form-input
            v-model="model.neighborhood"
            label="Bairro"
            name="neighborhood"
            rules="required"
            placeholder="Bairro"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="5"
          sm="5"
        >
          <i2-form-input
            v-model="model.address"
            label="Rua"
            name="address"
            rules="required"
            placeholder="Rua"
          />
        </b-col>
        <b-col
          md="2"
          sm="2"
        >
          <i2-form-input
            v-model="model.address_number"
            label="Número"
            name="address_number"
            rules="required"
            placeholder="999"
          />
        </b-col>
        <b-col
          md="5"
          sm="5"
        >
          <i2-form-input
            v-model="model.complement"
            label="Complemento"
            name="complement"
            placeholder=""
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-end text-right">
        <b-col>
          <b-button class="btn-icon" :style="buttonStyle" @click="saveParticipantData">
            <feather-icon icon="SaveIcon" style="font-size: 1.7em; padding-right:3px;"/>
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'ParticipantNewForm',
  components: { ValidationObserver },
  props: {
    document: {
      type: String,
      required: true,
    },
    headers: {
      type: Object,
      required: true,
    },
    buttonStyle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availableSexCollection: [],
      currentPromotion: null,
      model: {
        cpf: '',
        document: '',
        name: '',
        phone: '',
        email: '',
        birthdate: '',
        city: '',
        state: '',
        neighborhood: '',
        address: '',
        complement: '',
        zip_code: '',
        address_number: '',
        sex: null,
      },
    }
  },
  mounted() {
    this.model.cpf = this.document
    this.getAvailableSex()
  },
  methods: {
    async saveParticipantData() {
      this.$refs.refFormNewPersonObserver.validate().then(async success => {
        if (!success) {
          return
        }

        const document = this.model.cpf.replace(/\D/g, '')
        const response = await this.$http.post(`/person/${document}`, this.model, this.headers)
        if (response && response.erro_status) {
          this.$notify.error('Não foi possível salvar os dados do participante. Verifique os campos e tente novamente.')
          return
        }
        this.$notify.success('Participante salvo com sucesso.')
        this.$emit('onSave')
      })
    },
    async searchZipCode() {
      const response = await this.$http.get(`https://viacep.com.br/ws/${this.model.zip_code}/json/`)
      if (response.erro) {
        this.model.city = ''
        this.model.state = ''
        this.model.neighborhood = ''
        this.model.address = ''
        this.model.complement = ''
        this.model.address_number = ''
        return
      }
      this.model.city = response.localidade
      this.model.state = response.uf
      this.model.neighborhood = response.bairro
      this.model.address = response.logradouro
      this.model.complement = ''
      this.model.address_number = ''
    },
    async getAvailableSex() {
      this.availableSexCollection = []
      const response = await this.$http.get('/person/available-sex', this.headers)
      response.forEach(item => this.availableSexCollection.push({
        text: item.description,
        value: item.value,
      }))
    },
  },
}
</script>

<style scoped>
</style>
