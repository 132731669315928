<template>
  <div>
    <b-row>
      <b-col class="text-center">
        <h3>Digite abaixo os códigos de seus cupons.</h3>
      </b-col>
    </b-row>
    <validation-observer #default="{ handleSubmit }"
                         ref="refFormAddCuponObserver"
    >
      <b-form @submit.prevent="handleSubmit(addCoupon)">
        <b-row class="justify-content-center align-content-center align-items-center">
          <b-col md="3" sm="3">
            <i2-form-input
              v-model="coupon.code"
              label="Código"
              name="coupon_code"
              rules="required"
              placeholder="Digite o código"
            />
          </b-col>
          <b-col md="3" sm="3">
            <b-button class="btn-icon" :style="getStyle" @click="addCoupon">
              <feather-icon icon="PlusIcon" style="font-size: 1.7em; padding-right:3px;"/>
              Adicionar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="justify-content-center align-content-center align-items-center">
        <b-col md="8" sm="8">
          <b-table
            :items="itemsCode"
            :fields="fields"
            show-empty
            small
          >
            <template #empty class="text-center">
              <b-row class="text-center p-2">
                <b-col>
                  <b-img :src="require('@/assets/images/icons/empty.svg')" height="30"/>
                  Nenhum cupom lançado
                </b-col>
              </b-row>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="data.item.variant" class="d-block">
                {{ data.item.status }}
              </b-badge>
            </template>

            <template #cell(action)="data">
              <i2-icon-button
                variant="outline-danger"
                icon="Trash2Icon"
                @click="removeCoupomCode(data.item)"
              />
            </template>
          </b-table>

        </b-col>
      </b-row>
      <b-row class="justify-content-center align-content-center align-items-center">
        <b-col md="8" sm="8">
          <b-button block :disabled="itemsCode.length === 0" class="btn-icon" :style="{...getStyle, minHeight: '70px'}"
                    @click="getMyNumbers"
          >
            Gerar meus números
          </b-button>
        </b-col>
      </b-row>

    </validation-observer>
  </div>
</template>
<script>

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'ParticipantFormTextCoupon',
  components: { ValidationObserver },
  props: {
    getStyle: {},
    promotionUuid: {
      type: String,
      required: true,
    },
    httpConfig: {
      type: Object,
      required: true,
    },
    document: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      coupon: {
        code: '',
      },
      itemsCode: [],
      fields: [
        {
          key: 'code',
          label: 'Código',
          class: 'text-center',
        },
        {
          key: 'status',
          label: 'Situação',
          class: 'text-center',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'text-center',
        },
      ],
    }
  },
  methods: {
    async addCoupon() {
      this.$refs.refFormAddCuponObserver.validate().then(async success => {
        if (!success) {
          return
        }
        const response = await this.$http.get(`/promotion/${this.promotionUuid}/code/${this.coupon.code}`, this.httpConfig)
        let statusMessage = 'Válido'
        let variant = 'success'
        if (response === 'not_found') {
          this.$notify.error(`Cupom ${this.coupon.code.toUpperCase()} inválido.`)
          return
        }

        if (response === 'in_use') {
          this.$notify.warning(`Cupom ${this.coupon.code.toUpperCase()} já utilizado.`)
          return
        }

        const item = {
          code: this.coupon.code.toUpperCase(),
          status: statusMessage,
          variant,
        }
        this.itemsCode.push(item)
        this.coupon.code = ''
        this.$refs.refFormAddCuponObserver.reset()
      })
    },
    async getMyNumbers() {
      const data = {
        promotion_uuid: this.promotionUuid,
        codes: this.itemsCode,
      }
      const document = this.document.replace(/\D/g, '')
      const response = await this.$http.post(`/person/${document}/codes`, data, this.httpConfig)
      if (response && (response.error || response.error_status)) {
        this.$notify.error('Não foi possível gerar os números.')
        return
      }
      this.$notify.success('Números gerados com sucesso.')
      this.itemsCode = []
    },
    removeCoupomCode(item) {
      console.log('item', item)
      const items = []
      this.itemsCode.forEach(codeItem => {
        if (item.code !== codeItem.code) {
          items.push(codeItem)
        }
      })
      this.itemsCode = items
    },
  },
}
</script>
<style scoped>

@media (max-width: 768px) {
}

</style>
